import React from 'react'
import { Link } from 'gatsby'

function slugify(string) {
  return (
    string &&
    `${string}`
      .match(
        /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
      )
      .map((x) => x.toLowerCase())
      .join('-')
  )
}


export const ThoughtSidebar = ({
    date,
    timeToRead,
    tags = [],
    year,
    wordcount,
    toc
  }) => {
  //const categories = data.categories.group


  return (
    <aside className="post-sidebar-inpost">

      <div className="post-sidebar-card">
        <h2><span class="color-text">PUBLISH:</span> {date}</h2>
        <h2><span class="color-text">WORDS:</span> {wordcount.words}</h2>
        <h2><span class="color-text">READ:</span> {timeToRead} min</h2>
      </div>


      <div className="post-sidebar-card">
      <div className="tags">
          <h2><span class="color-text2">YEAR: </span></h2>
              <Link
                key={year}
                to={`/thought-years/${slugify(year.toString())}`}
                className="tag"
                activeClassName="active">
                {year}
              </Link>
        </div>

        <div className="tags">
        <h2><span class="color-text2">TAGS: </span></h2>
          {tags.map((tag) => {
            return (
              <Link
                key={tag}
                to={`/thought-tags/${slugify(tag)}`}
                className="tag"
                activeClassName="active">
                {tag}
              </Link>
            )
            })}
        </div>
      </div>

      { toc.length>0 && <div className="post-sidebar-card">
      <h2><span class="color-text3">TABLE OF CONTENT</span></h2>

        {toc.map((tocc) => {
            return (
              <h2><Link
                key={tocc[2]}
                to={`${tocc[1]}`}
                className={`toc${tocc[0]}`}
                activeClassName="active">
                {tocc[2]}
              </Link></h2>
            )
            })}
      </div>
      }

    </aside>
  )
}
import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import { MDXRenderer } from "gatsby-plugin-mdx"
import { MDXProvider } from "@mdx-js/react"

import { Layout } from '../Base/Layout'
import { ThoughtSidebar } from './ThoughtSidebar'
import { get_all_toc_item, get_string_pairs_for_replacing, protected_body, components } from '../Utilities/helpers'

export default function PostTemplate({ data }) {

  let i
  const post = data.allMdx.edges[0].node

  const timeToRead = post.timeToRead
  const year = post.fields.year
  const toc = post.tableOfContents
  const wordcount = post.wordCount
  const { tags, title, date, published, categories } = post.frontmatter
  let body = post.body

  const res_toc = get_all_toc_item(toc)
  if (published){
    const res = get_string_pairs_for_replacing(body, res_toc)
    for (i=0;i<res.length; i++){
      body = body.replace(res[i][3], res[i][4])
    }
  }else {
    body = protected_body(categories)
  }


  return (
    <>
      <Layout>
        <div>
        <Helmet title={title} />

        <article className="hero">
          <header>
            <div className="container-thoughts">
                <div className="flex-content">
                  <h1>{title}</h1>
                </div>        
 
            </div>
          </header>

          <section className="container markdown-content">
            <div className="grid">
              <div className="sidebar-content">
                <ThoughtSidebar date={date} timeToRead={timeToRead} tags={tags} year={year} wordcount={wordcount} toc={res_toc}/>
              </div>
              <div className="article-content">
                <MDXProvider components={components}>
                  <MDXRenderer>{body}</MDXRenderer>
                </MDXProvider>
              </div>
            </div>
          </section>

        </article>
      </div>
      </Layout>
    </>
  )
}


export const pageQuery = graphql`
query blogPostQuery($slug: String!) {
  allMdx (filter: { fields: { slug: { eq: $slug } } }) {
    edges {
      node {
        timeToRead
        tableOfContents
        wordCount {
          words
        }
        fields {
          slug
          year
        }
        body
        frontmatter {
          categories
          date(formatString: "MMMM DD, YYYY")
          published
          tags
          title
          toc
        }
        headings{
          depth
          value
        }
      }
    }
  }
}
`